<script lang="ts" setup>
	import type { Image } from "~/server/models/mixins/image";

	const {
		public: { cloudflare },
	} = useRuntimeConfig();

	const props = withDefaults(
		defineProps<{
			value: Image;
			width: number;
			height?: number;
			alt?: string;
			fit?: "scale-down" | "cover";
			density?: number;
		}>(),
		{
			fit: "scale-down",
			density: 2,
		}
	);

	type Keys = {
		width?: number;
		height?: number;
		fit: "scale-down" | "cover";
		dpr: number;
	};

	function getUrl(id: string) {
		const params: Keys = {
			fit: props.fit,
			dpr: props.density,
		};

		if (props.width) params.width = props.width;
		if (props.height) params.height = props.height;

		//

		const query: string = Object.keys(params)
			.map((key) => {
				return `${key}=${(params as any)[key]}`;
			})
			.join(",");

		return `https://images.astonmartindrive.com/cdn-cgi/imagedelivery/${cloudflare.accountHash}/${id}/${query}`;
	}

	const src = computed(() => {
		return getUrl(props.value.id);
	});
</script>

<template>
	<img
		:style="{
			'background-image': props.value.base64 ? `url(data:image/jpg;base64,${props.value.base64})` : undefined,
		}"
		loading="lazy"
		decoding="async"
		class="cloudflare-image"
		:src="src"
		:alt="props.alt"
	/>
</template>

<style scoped lang="scss">
	.cloudflare-image {
		background: var(--beige-light);
		background-size: cover;
		background-position: center center;
		object-fit: cover;
		object-position: center center;
	}
</style>
